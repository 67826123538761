ul.footnotes {
  font-size: .9em;
  li {
    margin-left: 0;
    margin-left: 2.5em;
    .footnote-label {
      left: auto;
      left: 0;
    }
  }
}
ul.footnotes {
  @include breakpoint(medium) {
    display: none;
  }
  li {
    margin-left: 1.5em;
  }
}
.see-footnote, .footnote-label {
  text-decoration: none !important;
  border: none !important;
  font-weight: bold;
  color: $body-font-color;
}
.footnote-container {
  position: relative;
  .footnote-contents {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
    position: absolute;
    color: $body-font-color;
    width: 200px;
    padding: 0 .5em;
    left: 100%;
    top: 0;
    &.footnote-opposite {
      left: auto;
      right: 100%;
      max-width: 160px;
      margin-right: 0;
      margin-left: .25em;
    }
  }

  .footnote-content {
    margin: 0 0 1em;
    color: $dark-gray;
    font-size: .9em;
    .footnote-label {
      color: $body-font-color;
      font-weight: bold;
    }
  }
}
