 .view-reports.view-display-id-panel_pane_1 {
  @include grid-row;
  .view-content{
    @include xy-grid-frame(false, true);
    display: flex;
    .views-row{
      @include xy-cell-base;
      @include xy-cell(12);
      @include breakpoint(medium){
        @include xy-cell(6);
      }
      @include breakpoint(large){
      @include xy-cell(4);
      }
    }
 }
}