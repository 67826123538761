// Drupal comes with CSS quirks (Drupalisms). Unravel or override them here.

// Make the "sticky" Top Bar play nice with the "fixed" Admin Menu.
.admin-menu {
  .is-stuck {
    top: 29px !important;
  }
}
.navbar-fixed {
  .is-stuck {
    top: 39px !important;
  }
}
// Remove the bottom padding on status messages when displayed inside a Zurb
// Foundation Reveal modal.
#status-messages.reveal-modal {
  .alert-box {
    margin-bottom: 0;
  }
}
// Override Drupal Core pager styles. This is necessary because we need to keep
// the .pager class on pagers for Views AJAX to work. If you are disabling
// Drupal Core CSS you can safely comment the following lines.
.item-list .pager {
  clear: none;
}
.item-list .pager li {
  padding: 0;
}
// Allow drupal inline elements to mixin Foundation inline properties
.inline {
  //@include inline-list;
}
.contain-to-grid {
  @include grid-row;
}
.sticky {
  width: 100%;
}
.primary-tabs {
  @include breakpoint(medium) {
  }
  width: 100%;
  margin-bottom: 0;

  .button {
    margin-bottom: 0;
  }
}
#status-messages {
  padding: 0;
  .callout {
    margin-bottom: 0;
  }
}
#navbar-administration {
  .navbar-bar {
    .navbar-icon {
      overflow: hidden;
    }
  }
}
.links.inline {
  @include menu-simple();
}
.grippie {
  display: none !important;
}



form{
  button,.form-submit{
    @include button();
    text-transform: uppercase;
    font-weight: bold;
  }
}
