.flexslider {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: none;
  &.optionset-default {
    background: none;
    .flex-control-nav {
      bottom: 100%;
      margin-bottom: -5px;
    }
    .flex-control-thumbs {
      background: $primary-color;
      overflow: hidden;

    }
  }
  &.optionset-carousel {
    $icon-size: 32px;
    //width: calc(100% + 64px);
    padding: 0 $icon-size 0;
    //margin-left: -$icon-size;
    margin: 0 -48px;
    .flex-control-nav {
      bottom: 0;
      top: auto;
      z-index: 2;
    }
    .slides {

      li {
        article {
          margin: 0 1rem;
        }
        &:first-child {
          //&:nth-child(odd){
          article {
            //margin-left: 0;
          }
        }
        &:last-child {
          //&:nth-child(even){
          article {
            //margin-right: 0;
          }
        }

      }
    }
    &.flexslider-processed {
      .slides {

      }
    }
    .node-title {
      display: none;
    }

    .flex-control-paging {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }
    .flex-direction-nav {
      display: block;
      a {
        display: block;
        font-size: 0;
        width: $icon-size;
        height: $icon-size;
        margin-top: -$icon-size/2;
        text-align: center;
        opacity: 1;
        &:before {
          @include icon;
          //content: $icon-angle-right;
          //@include rtlcss_prop_value('content', $icon-angle-right, $icon-angle-left);
          font-size: $icon-size;
          margin: 0;
          width: 100%;
          color: $black;
          display: block;

        }
        &.flex-disabled {
          opacity: 0;
        }
        &.flex-next {
          right: 1rem;
          left: auto;
          float: left;
          &:before {
            content: $icon-angle-right #{"/*rtl:"#{$icon-angle-left}"*/"};

          }
        }
        &.flex-prev {
          left: 1rem;
          right: auto;
          float: right;

          &:before {
            //content: $icon-angle-left;
            //@include rtlcss_prop_value('content', $icon-angle-left, $icon-angle-right);
            //content: $icon-angle-left #{"/*rtl:#{$icon-angle-right}*/"};
            content: $icon-angle-left #{"/*rtl:"#{$icon-angle-right}"*/"};
          }
        }
      }
      @include breakpoint(medium) {
        //display: none;
      }
    }
  }
  .flex-direction-nav {
    a {
      line-height: 1;
      &:before {
        color: $primary-color;
      }
    }
  }

  .flex-control-paging {
    li {

      a {
        background-color: rgba($black, .3);
        &.flex-active {
          background-color: $primary-color;
        }
      }
    }

  }
}