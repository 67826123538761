.l-main {
  position: relative;
  z-index: 4;
}

html {
  overflow-x: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  position: initial;
}

.main {
  position: relative;
  .primary-tabs {
    @include breakpoint(medium) {

      position: absolute;
      top: 0;
      right: 100%;
      width: auto;
      z-index: 10;
      padding: 1rem 0;
      border-right: 1px solid;
      .button {
        display: block;
        margin: 0;
      }
    }
  }
}

.not-front.no-sidebars {
  .main {
    @include breakpoint(medium) {
      max-width: 600px;
      min-height: 480px;
    }
  }
}

.block-system-main {
  clear: both;
}

#page-title {
  font-size: 2.5em;
  margin: 0 0 1em;
  line-height: 1.1;
}