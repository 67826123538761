.block-locale-language-content {
  @include breakpoint(medium) {
    border-top: 2px dotted;
    border-bottom: 2px dotted;
  }

}

.language-switcher-locale-url {
  margin: .5em 0;
  padding: 0;
  list-style: none;

  width: 100%;

  overflow: hidden;
  float: right;
  @include breakpoint(medium) {
    width: 75%;
  }
  li {
    margin: 0;
    padding: 0;

    line-height: 1;
    position: relative;
    display: block;
    width: 100%;

    @include breakpoint(medium) {
      width: 50%;
      float: left;
      border-left: 1px solid;
    }
    .language-link {

      text-transform: uppercase;
      display: block;

      font-family: $header-font-family;
      //font-weight: $header-font-weight;
      color: $body-font-color;
      font-size: 0;
      padding: 0;
      text-align: right;

      &:before {
        font-size: 1.5rem;
        line-height: 1em;

      }
      &:hover,
      &:active,
      &:focus {
        color: $primary-color;
      }
      &.active {
        color: $body-font-color;
        font-weight: bold;
      }

      @include breakpoint(medium) {
        text-align: center;
        &:before {
          font-size: 1.25rem;
        }
      }
    }
    &.last {

      .language-link {
        //padding-right: 0;

      }
    }

    &.first {
      border-left: none;
      .language-link {
        //padding-left: 0;
      }
    }
    &.en {
      .language-link {
        //font-family: $font-family-en;
        &:before {
          content: 'en';

        }
      }
    }

    &.ar {
      .language-link {
        //font-family: $font-family-ar;
        &:before {
          content: 'ع';
        }
      }
    }

    &.active {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
      &.first + li {
        //border-left: none;
      }
    }
  }
  &:hover {

    li {

      .language-link {

      }
    }
  }
}