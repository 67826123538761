.field-collection-item-field-slides {
  position: relative;
  .field-name-field-link {
    a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      padding: .5em;
      font-weight: $global-weight-bold;
      max-width: 300px;
      width: 90%;
      text-align: center;
      font-size: 2em;
      background: rgba(0, 0, 0, 0.5);
      line-height: 1.1;
    }
  }
}