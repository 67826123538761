.file {
  .field {
    margin: 0 0 1em;
    &.field-label-inline {
      overflow: hidden;
    }
  }

  .content{
    overflow: hidden;
  }
  iframe{
    width:100%;
    float:left;
  }

  table {
    background: none;
    border: none;
    display: block;
    width:100%;
    &.sticky-header{
      display: none;
    }
    thead {
      display: none;
    }
    tbody {
      border: none;
      display: block;
      tr,
      td {
        background: none!important;
        border: none;
        padding: 0;
        display: block;
        width:100%;
      }
      .file {
        .file-icon {
          display: none;
        }
        a {
          color: $body-font-color;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          margin-bottom:.25em;
          &:before {
          @include icon();
          content: $icon-download-alt;
          margin-right:.25em;
        }
        }
      }
      td {
        font-size: 1.1em;

      }
      td:nth-child(1) {

      }
      td:nth-child(2) {
        padding: 0 .25em;
        &:before {
          content: '(';
        }
        &:after {
          content: ')';
        }
      }
    }
  }
}



@import 'document';
@import 'video';
@import 'audio';
@import 'image';
