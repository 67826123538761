.node-article {
  &.node-teaser {
    > .content{
      display: flex;
      flex-wrap: wrap;
      .group-meta{
        width: 100%;
      }
      .group-image {
        width: calc(33% - 1rem);
        margin-left: 1rem;
      }
      .group-text{
        width: calc(67% - 1rem);
        margin-left: 1rem;
      }
    }
    .group-text {
      position: relative;
    }

    .field-name-field-link {
      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 3;
        font-size: 0;
      }

    }
    .links {
      display: none;
    }
  }

  &.view-mode-full {
    .field-name-field-media {
      .file {
        margin: 0 0 1em;
      }
    }
  }

 &.view-mode-compact{
    .content{
      display: flex;
    }
    .field-name-field-image{
      width: 25%;
      padding-left: .5rem;
    }
   .field-name-title-field{
     width: calc(67% - 1rem);
   }
    .node__title{
      color: $black;
      font-size: 1.4em;
      a{
        color: inherit;
      }
    }
  }
}

.view-taxonomy-terms.view-display-id-block_1 {
  .views-row {
    margin-bottom: .5em;
    border-bottom: 1px dashed $black;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.view-resources {
  .views-row {
    margin-bottom: .5em;

    border-bottom: 1px dashed $black;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .views-row {
    display: flex;
    > * {
      margin-bottom:.25em;
    }
  }

  .views-field-rendered-entity {

    width: 64px;
    margin-left: 1em;

    .file {
      margin: 0;
      padding: 0;
      position: static;
      height: auto;
    }
    img{
      height: auto;
      width: 100%;
    }
  }

  .views-field-title {
    width: calc(100% - 64px - 1em);
    a {
      color: $black;
      font-size: 1.4em;
    }
  }
}
