
.node-project {
  &.node-teaser {
    position: relative;

    .field-name-field-link {
      a {
        font-size: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .field-name-body {
      margin-top: .5em;
      font-weight:$global-weight-bold;
    }

  }
}

.view-projects .views-row {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}
