$logo-height: 64px;
$small-logo-height: $logo-height/2;
.l-header {
  > .grid-x {
    align-items: center;

  }
  padding: 1em 0 3em;
  margin-bottom: 2em;
  position: relative;
  z-index: 5;

  .brand {

    .logo {

      max-width: 100%;
    }
  }

  .burger-menu {
    .block-title {
      float: right;
    }
  }

  @include breakpoint(medium) {
    padding-bottom: 0em;
    .region {

      margin-top: 0;
      .grid-x {
        align-items: flex-end;
      }
      .burger-menu {

        .block-title {
          float: left;
        }
      }

    }
  }
}