
.node-longread {
  &.node-teaser {
    position: relative;

    .field-name-field-link {
      a {
        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: $black;
        font-weight: bold;
        font-size:1.2em;
      }
    }

    .field-name-body {
      font-size: 1.4em;
    }

  }
}

.view-longreads .views-row {
  margin-bottom: .5em;
  border-bottom: 1px dashed $black;

  &:last-child {
    margin-bottom: 0;
  }
}
