.front {
}

#front {
  > .columns {
    padding: 0;
  }

  .panel-separator {
    clear: both;
    @include breakpoint(medium) {
      display: none;
    }
  }

  > .grid-x {
    margin: 0 0 2em;
  }

  .pane-title {
    border-bottom: 2px solid $primary-color;
    font-size: 1.2em;
    font-weight: $global-weight-bold;
    span {
      background: $primary-color;
      color: $white;
      display: inline-block;
      padding: .125em .5em;
      padding-left: 2em;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .panel-pane {

    .node {
      .field {
        //text-align: center;
      }

      .field-name-body {
        p {
          margin: 0 0 .5em;
        }
      }
    }

    &.wide {
      .pane-content {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        .field-name-field-link {
          a {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 3;
            font-size: 0;
            text-align: center;
          }

        }
      }
    }

    &.pane-bean-front-block {
      .bean-static {
        height: 100%;

        .content {
          height: 100%;

          .field-name-field-image {
            height: 100%;

            img {
              @include breakpoint(medium) {
                min-height: 320px;
                height: 100%;
              }
            }
          }

        }
      }
    }
  }

  .middle {
    .panel-pane {
      padding: 0 1em;
     
    }
  }
  .bottom-grid{
    .panel-pane{
      padding: 0 1em;
    }
  }
  .bottom{
    .panel-pane{
      margin-bottom: 1rem;
    }
  }

  /*.top-first {
    .panel-pane {
      .field-name-field-link {
        a {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 3;
          font-size: 0;
          text-align: center;
          &:after {
            @include icon;
            $icon-size: 64px;
            content: $icon-play-circled2;
            background: rgba($black, .5);
            border-radius: 100%;
            font-size: $icon-size;
            top: 50%;
            left: 50%;
            margin-top: -$icon-size/2;
            margin-left: -$icon-size/2;
            color: $white;
            position: absolute;
            width: $icon-size;
            height: $icon-size;

          }
        }

      }
    }
  }*/
  .top-last {

    .panel-pane {
      margin: 0 0 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      padding: 0;

      .node-title, .block-title {
        //text-align: center;
      }

      .pane-content {
      }

      .node {
        .field-name-field-article-types {
          display: none;
        }

      }

    }

    .pane-bean-tours-sticker {
      position: relative;
      margin-top: .5em;

      .field-name-field-image {
        text-align: center;

        img {
          max-width: 220px;
        }
      }

      .field-name-field-link {
        a {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          font-size: 0;
          display: block;
        }
      }
    }

  }
}
