.node {
  .field-name-body {
    p {
      margin: 1em 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .field-name-field-article-types {
    //text-align: center;
    a {
      display: inline-block;
      margin-right: .5em;
      padding: 0 .5em;
      color: $body-background;
      background: $primary-color;
      font-weight: $global-weight-bold;
    }
  }
  .node-title {
    color: $body-font-color;
    font-size: 1.75em;
    a {
      color: inherit;
    }
  }

}

.node-listing {
  .views-row {
    margin: 2em 0;
    padding: 1em 0;
    border-bottom: 1px solid;
    &.views-row-first {
      margin-top: 0;

    }
    &.views-row-last {
      margin-bottom: 0;
      border-bottom: none;

    }
  }
}

.node.view-mode-full{
  table{
    @include breakpoint(medium) {
      width: 150%;
    }
  }
}
