
@font-face {
  font-family: "Symbio";
  font-weight:100;
  src: url("../fonts/symbio/symbio-thin.eot");
  src: url("../fonts/symbio/symbio-thin.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-thin.woff2") format("woff2"), url("../fonts/symbio/symbio-thin.woff") format("woff"), url("../fonts/symbio/symbio-thin.svg#symbio") format("svg");
}
@font-face {
  font-family: "Symbio";
  font-weight:300;
  src: url("../fonts/symbio/symbio-light.eot");
  src: url("../fonts/symbio/symbio-light.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-light.woff2") format("woff2"), url("../fonts/symbio/symbio-light.woff") format("woff"), url("../fonts/symbio/symbio-light.svg#TPTQ_Arabic_webfonts_service") format("svg");
}
@font-face {
  font-family: "Symbio";
  font-weight: 400;
  src: url("../fonts/symbio/symbio-regular.eot");
  src: url("../fonts/symbio/symbio-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-regular.woff2") format("woff2"), url("../fonts/symbio/symbio-regular.woff") format("woff"), url("../fonts/symbio/symbio-regular.svg#TPTQ_Arabic_webfonts_service") format("svg");
}
@font-face {
  font-family: "Symbio";
  font-weight:600;
  src: url("../fonts/symbio/symbio-medium.eot");
  src: url("../fonts/symbio/symbio-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-medium.woff2") format("woff2"), url("../fonts/symbio/symbio-medium.woff") format("woff"), url("../fonts/symbio/symbio-medium.svg#TPTQ_Arabic_webfonts_service") format("svg");
}
@font-face {
  font-family: "Symbio";
  font-weight: 700;
  src: url("../fonts/symbio/symbio-bold.eot");
  src: url("../fonts/symbio/symbio-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-bold.woff2") format("woff2"), url("../fonts/symbio/symbio-bold.woff") format("woff"), url("../fonts/symbio/symbio-bold.svg#TPTQ_Arabic_webfonts_service") format("svg");
}
@font-face {
  font-family: "Symbio Black";
  font-weight: 800;
  src: url("../fonts/symbio/symbio-black.eot");
  src: url("../fonts/symbio/symbio-black.eot?#iefix") format("embedded-opentype"), url("../fonts/symbio/symbio-black.woff2") format("woff2"), url("../fonts/symbio/symbio-black.woff") format("woff"), url("../fonts/symbio/symbio-black.svg#TPTQ_Arabic_webfonts_service") format("svg");
}
