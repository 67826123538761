.menu {
  .with-logo a, a.with-logo {

    background: none !important;

    &.active {
      background: none;
    }

  }
}

.toggle-menu {
  position: relative;

  .block-title {
    cursor: pointer;
  }

  .menu-block-wrapper {

    display: none;
    top: 100%;
    left: 0;
    right: auto;
    max-width: 220px;
    position: absolute;
  }

  &.active {

    .menu-block-wrapper {
      display: block;
    }
  }
}

.burger-menu {
  z-index: 3;
  @include clearfix;

  .block-title {
    font-size: 0;
    margin: 0;
    z-index: 3;
    line-height: $small-logo-height;
    height: $small-logo-height;

    &:before {
      @include icon;
      content: $icon-marsad-menu;
      font-size: 1.5rem;
      margin: 0;
      line-height: $small-logo-height;

    }
  }

  &.active {

    .block-title {

      &:before {
        content: $icon-marsad-close;

      }
    }

  }

  .menu-block-wrapper {
    right: 0;
    left: auto;
    width: 200px;
    @include breakpoint(medium) {
      left: 0;
      right: auto;
    }
  }

  .menu-block-wrapper {
    > .menu {
      padding: .5em 0;

      > li {
        border-top: 2px dashed;
        padding: 0 1em;
        margin: 0;

        > a {
          font-size: 1.25em;
          font-weight: $global-weight-bold;
        }

        li {
          border-top: 1px dashed;
        }
      }
    }
  }

  .menu {
    background-color: $medium-gray;
    //background-image: linear-gradient(rgba(#B3B3B3, .75) 25%, transparent 25%);
    //background-size: 25px 25px;

    li {
      width: 100%;
      padding: 0;
      margin: 0;

      a {
        display: block;
        color: $body-font-color;
        font-size: 1.1em;
        padding: .3em 0;

        &.active, &:hover, &:focus, &:active {
          background: none;
          color: $primary-color;
        }
      }

    }
  }

  @include breakpoint(medium) {
    background: none;
    .block-title {
      line-height: $small-logo-height;
      height: $small-logo-height;
      margin: 0;

      &:before {
        line-height: $small-logo-height;
      }
    }

    &.active {
      .block-title {

        &:before {

        }

      }

    }
  }
}

.block-menu-block-2 {
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  position: absolute;
  bottom: 0;
  right: 0.625rem;
  left: 0.625rem;
  width: auto;
  @include breakpoint(medium) {
    position: relative;
    left: 0;
    right: 0;

  }
  padding: 0;

  .menu-block-wrapper {

    right: 0;
    max-width: none;
    background: $dark-gray;
    color: $white;

  }

  .menu {
    padding: .5rem 1rem 1rem;

    .nolink {
      font-size: 1.25em;
      font-weight: $global-weight-bold;
      color: $black;
    }

    .separator {
      display: none;
    }

    .menu {
      padding: 0;
      margin-left: 1em;

      li {
        border-bottom: 1px dashed $black;
      }
    }

    li {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $white;
      display: block;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: block;
        color: inherit;
        font-weight: $global-weight-bold;
        padding: .75em 0;
        font-size: 1.1em;
      }
    }
  }

  .block-title {
    overflow: hidden;
    color: $black;

    font-weight: $global-weight-bold;

    span {
      background: $primary-color url('../img/loudspeaker-icon.png') right center no-repeat;
      background-position: right .5em center;
      background-size: auto 80%;
      display: inline-block;
      padding: .25rem 1rem;
      width: 50%;
      border-left: 2px dotted;
      border-right: 2px dotted;
    }

    cursor: pointer;
    font-size: 1.25rem;
    margin: 0;

  }

  &.active {
    .block-title {
      span {
        width: 100%;
      }
    }
  }
}
