.node-webform {
  position: relative;
  .webform-client-form {
    position: static;
    .form-radios {
      overflow: hidden;
      .form-type-radio {
        float: left;
        margin-right: .5em;
        margin-bottom: .5em;
        .form-radio {
          display: none;
        }
        label {
          display: block;
          padding: 0 .5em;
          margin: 0 0 .5em;
          background: $white;
          color: $primary-color;
          font-weight: $global-weight-normal;
          border: 1px solid;
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
          &:hover {
            //font-weight:$global-weight-bold;
          }
        }
        .form-radio:checked + label {
          background: $primary-color;
          color: $white;
          //font-weight:$global-weight-bold;
        }
        .form-radio[value=_none] + label {
          display: none;
        }
      }
    }

    .form-checkboxes {
      overflow: hidden;
      .form-type-checkbox {
        float: left;
        margin-right: .5em;
        margin-bottom: .5em;
        .form-checkbox {
          display: none;
        }
        label {
          display: block;
          padding: 0 .5em;
          margin: 0 0 .5em;
          background: $white;
          color: $primary-color;
          border: 1px solid;
          font-weight: $global-weight-normal;
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
          &:hover {
            //font-weight:$global-weight-bold;
          }
        }
        .form-checkbox:checked + label {
          background: $primary-color;
          color: $white;
          //font-weight:$global-weight-bold;
        }
        .form-checkbox[value=_none] + label {
          display: none;
        }
      }
    }

    .field-widget-options-onoff {
      overflow: hidden;
      .form-type-checkbox {
        float: left;
        margin-right: .5em;
        .form-checkbox {
          display: none;
        }
        label {
          display: block;
          &:before {
            @include icon;
            content: $icon-check-empty;
            //color:$white;
            width: 1em;
            text-align: left;
            margin-right: .25em;
          }
          padding: 0 .5em;
          margin: 0 0 .5em;
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
          &:hover {
          }
        }
        .form-checkbox:checked + label {
          &:before {
            content: $icon-check;
          }
        }

      }
    }

    .form-textarea-wrapper {
      .grippie {
        display: none;
      }
    }

    $star-size: 32px;
    .fivestar-outline {
      div.fivestar-widget {
        .cancel, .star {
          width: $star-size;
          height: $star-size;
          background: none !important;
          text-indent: 0 !important;
          position: relative;
          text-align: center;
          line-height: $star-size;
          font-size: 0;
          &:before {
            @include icon;
            content: $icon-star-empty;
            font-size: $star-size;
          }

          a {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: none !important;

            &:before {
              @include icon;
              font-size: $star-size;
            }

          }

          &.hover {

            a {
              &:before {
                content: $icon-star;
              }
            }
          }
          &.on {
            a {
              &:before {
                content: $icon-star;
              }
            }
          }
        }
      }
    }

    .webform-component-fieldset {
      clear: both;

      .fieldset-legend {
        padding-right: 0;
        background: none;
        font-size: 1.5em;
      }
      .fieldset-title {
        font-size: 1.1em;
        color: #000;
      }

      &.collapsible {
        > legend {
          .fieldset-legend {

            &:before {
              @include icon();
              content: $icon-minus;
            }
          }
        }
      }
      &.collapsed {
        height: 3em;
        > legend {
          .fieldset-legend {
            padding-right: 0;
            background: none;
            &:before {
              @include icon();
              content: $icon-plus;
            }
          }
        }
      }
    }

    .image-widget-data {
      position: relative;
      .form-submit {
        border-radius: $input-radius;
        padding: .25em;
        position: absolute;
        right: 0;
        top: 0;
      }
      .form-file {
        float: left;
      }
    }

    label {
      font-weight: $global-weight-bold;
      //position: relative;
      .form-required {
      }
    }
    > div > .form-submit {
      display: block;
      width: 100%;
      font-weight: $global-weight-bold;
      font-size: 1.5em;
      margin: 1em 0;
      &:hover {
      }
    }

    $label-width: 20%;
    $input-width: 80%;
    $label-align: left;
    $label-padding2: .5em;

    .form-item {
      margin-bottom: 0;
      margin-top: 0;
      .form-text, .form-select, .form-textarea, .form-radio {
        margin-bottom: 0 !important;
        margin-top: 0;
      }
    }

    @mixin apply-label {

      @include breakpoint(medium) {
        float: left;
        width: $label-width;
        padding-right: $label-padding2;
        font-size: 1.2em;
        line-height: 1.2;
      }
      text-align: $label-align;
    }
    @mixin apply-input {
      @include breakpoint(medium) {
        float: left;
        width: $input-width;
        margin: 0 .5rem;
      }
      margin: 0;
    }
    @mixin apply-wrap {
      overflow: hidden;
      margin: 0 0 2em !important;
      float: left;
      width: 100%;
      clear: both;
      @include breakpoint(medium) {
        display: flex;
      }
    }
    .form-wrapper {
      clear: both;
    }

    .description {
      @include breakpoint(medium) {
        margin-left: $label-width;
        clear: both;
      }
    }
    .form-type-media-recorder, .field-widget-text-textarea {
      .description {
        margin-left: 0;
      }
    }

    .field-widget-text-textarea {
      > div > .form-item {
        @include apply-wrap;
      }
      label {
        font-size: 1.2em;
      }

    }

    .webform-component-textfield,
    .webform-component-email,
    .webform-component-number {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .form-text {
        @include apply-input;
      }

    }

    .webform-component-select {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .form-select {
        @include apply-input;
      }

    }

    .webform-component-date {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .webform-container-inline {
        @include apply-input;
        .form-item {
          float: left;
        }
      }

    }

    .webform-component-file {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .form-managed-file {
        @include apply-input;
      }

    }

    .webform-component-radios {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .form-radios {
        @include apply-input;
      }

    }

    .webform-component-checkboxes {
      @include apply-wrap;
      > label {
        @include apply-label;
      }
      > .form-checkboxes {
        @include apply-input;
      }

    }

    .webform-component-select-or-other {
      width: 100%;
      clear: both;
      overflow: hidden;
      .form-type-select {
        @include apply-wrap;
        > label {
          @include apply-label;
        }
        .select-or-other-select {
          @include apply-input;
        }
      }
      .form-type-textfield {
        @include apply-wrap;
        > label {
          @include apply-label;
        }
        .select-or-other-other {
          @include apply-input;
          margin-right: auto;
        }
      }
    }

    .pre-instructions {
      text-align: center;
      margin-bottom: 3em;

      h2 {
        font-size: 2em;
        @include breakpoint(medium) {
          font-size: 3em;
        }
        @include breakpoint(large) {
          font-size: 4em;
        }
      }

    }

    .form-text, .form-select, .form-textarea .form-file, .form-radio, .form-checkbox, .form-file {
      &.error {
        border: 2px solid $alert-color !important;
        + label {
          border: 2px solid $alert-color !important;
        }
      }
    }

    .webform-component--map {
      position: relative;
      p {
        margin: 0;
        bottom: 100%;
        left: 0;
      }
      @include breakpoint (medium) {
        position: absolute;
        left: 100%;
        top: -5em;
        width: calc(((100vw - 1200px) / 2) + (1200px - 600px) - 2em);
        margin-left: 1em;
      }

      #beirut {
        /*!rtl:begin:ignore*/
        direction: ltr;
        text-align: left;
        /*!rtl:begin:ignore*/
        @include breakpoint (medium) {
          height: calc(100vh - 160px);
        }
        min-height: 480px;
        width: 100%;
        overflow: hidden;
      }
    }

    .webform-progressbar {
      display: none;
    }

  }

}