.file-document {



}

.field-name-field-media {
  .file-document {
    //&.file-text-plain {
      .content {
        &:before {
          content: $icon-doc-text;
        }
      }
    //}
    &.file-application-msword {
      .content {
        &:before {
          content: $icon-file-word;
        }
      }
    }

    &.file-application-pdf {
      .content {
        &:before {
          content: $icon-file-pdf;
        }
      }
    }

  }
}

.lang-ar{
  .field-name-field-media {
      .file-document{
        &.lang-1{
          display: none;
        }
      }
  }
}

.lang-en{
  .field-name-field-media {
      .file-document{
        &.lang-2{
          display: none;
        }
      }
  }
}
