.video-filter,
.file-video {
  &.file-video-youtube {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    display: block;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  video {
    max-width: 100%;
  }
}

.video-filter {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  display: block;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.field-name-field-media {
  .file-video {

  }
}

.view-videos {
  .views-row {
    margin-bottom: .5em;

    border-bottom: 1px dashed $black;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .views-row {
    display: flex;
    > * {
      margin-bottom:.25em;
    }
  }

  .views-field-rendered-entity {

    width: 64px;
    margin-left: 1em;

    .file {
      margin: 0;
      padding: 0;
      position: static;
      height: auto;
    }
    img{
      height: auto;
      width: 100%;
    }
  }

  .views-field-filename {
    width: calc(100% - 64px - 1em);
    a {
      color: $black;
      font-size: 1.4em;
    }
  }
}
