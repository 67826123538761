.field-name-field-media {
  .file-image {

    .content {
      &:before {
        //content: $icon-file-image;
      }
    }

  }
}

.file-image{
  .content{
    @include clearfix;
    >img + br{
      display: none;
    }


    .field-name-field-caption{
      font-size:.9em;
      color:$dark-gray;

    }
  }
}
