.entity-bean {
  > h2 {
    display: none;
  }
  .content {
    .field-name-field-body {
      p {
        margin: .5em 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}