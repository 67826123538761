.file-audio {
  audio {
    width: 100%;
    margin: 0;
  }
  iframe {
    width: 100%;
    height: auto;
  }
}

.field-name-field-media {
  .file-audio {

  }
}
