.bean-hero {
  .content {
    position: relative;

    .field-name-title-field {

      .block-title {
        color: inherit;
        font-size: 1.25em;
        @include breakpoint(medium) {
          font-size: 1.75em;
        }
        margin: 0 0 1em;

        a {
          color: inherit;
        }
      }
    }

    .field-name-field-image {
      img {
        width: 100%;
      }

      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        background: rgba($black, .33);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .field-name-field-link {
      margin: .25em 0 0;
      text-align: center;

      a {
        font-weight: $global-weight-bold;
      }

    }

    .field-name-field-body {
      font-size: 1.25em;
    }

    .group-text {

      margin: 0 auto;
      background: none;
      padding: 2em;
      color: $white;
      background: rgba($black, .75);
      text-align: center;
      @include breakpoint(medium) {
        max-width: 960px;
        position: absolute;
        bottom: auto;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        right: auto;
      }
    }

  }

}
