.bean-static {
  .content {
    position: relative;
    .field-name-title-field {

      .block-title {
        color: inherit;
        font-size: 1.25em;
        @include breakpoint(medium) {
          font-size: 1.75em;
        }
        margin: 0;
        a {
          color: inherit;
        }
      }
    }
    .field-name-field-image {
      img {
        width: 100%;
      }
    }
    .field-name-field-link {
      margin: .25em 0 0;
      text-align: center;
      a {
        font-weight: $global-weight-bold;
      }

    }
    .field-name-field-tags {
      text-align: center;
      a {
        display: inline-block;
        margin: 0 .5em;
        padding: 0 .5em;
        color: $body-background;
        background: $primary-color;
        font-weight: $global-weight-bold;
      }
    }
  }

}

.pane-bean-front-block {
  .field-name-title-field {
    position: absolute;
    right: 0;
    bottom: 2em;
    color: $white;
    background: $medium-gray;
    padding: 0 .5em;
    width: 50%;
    text-align: center;
    font-weight: bold;
    line-height: 1;
    .block-title {
      color: inherit;
      font-size: 1.75em;
      margin: 0;
      a {
        color: inherit;
      }
    }
  }
}

.pane-bean-beirut-evictions {
  .bean-static {

    .content {
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        background: rgba($black, .33);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .field-name-field-image {

      padding-bottom: 20%;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
      }

    }
    .field-name-title-field {
      position: absolute;
      z-index: 2;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
      right: auto;
      margin: 0 auto;
      background: none;
      border: 2px solid;
      padding: 0 2em;
      color: $white;

      text-align:center;
      width: 90%;
      @include breakpoint(medium) {
        width: auto;
      }

      &:before, &:after {
        content: '';
        border: 2px solid;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        width: 1em;

      }
      &:after {
        left: auto;
        right: 0
      }
    }
  }
}