
.node-guide {
  &.node-teaser {
    .node-title{
      font-size:1.25em;
    }
  }
}

.view-guides .views-row {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}
